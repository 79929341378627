import * as React from "react"

type IconProps = {
	className?: string
	title?: string
} & Omit<React.JSX.IntrinsicElements["svg"], "children" | "viewbox">

export function Archive(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Archive icon" {...props} viewBox="0 0 424.96 424.96">
			<path
				fill="currentColor"
				d="M340.48,112.64h-256c-25.6,0-46.08,20.48-46.08,46.08v220.16c0,25.6,20.48,46.08,46.08,46.08h256
			c25.6,0,46.08-20.48,46.08-46.08V158.72C386.56,133.12,366.08,112.64,340.48,112.64z M285.696,237.056
			c0,8.704-6.656,15.36-15.36,15.36H154.624c-8.704,0-15.36-6.656-15.36-15.36v-38.912c0-8.704,6.656-15.36,15.36-15.36
			c8.704,0,15.36,6.656,15.36,15.36v23.552h84.992v-23.552c0-8.704,6.656-15.36,15.36-15.36c8.704,0,15.36,6.656,15.36,15.36
			V237.056z"
			/>
			<path
				fill="currentColor"
				d="M340.48,56.32h-256c-8.704,0-15.36,6.656-15.36,15.36c0,8.704,6.656,15.36,15.36,15.36h256
			c8.704,0,15.36-6.656,15.36-15.36C355.84,62.976,349.184,56.32,340.48,56.32z"
			/>
			<path
				fill="currentColor"
				d="M314.88,0h-204.8c-8.704,0-15.36,6.656-15.36,15.36s6.656,15.36,15.36,15.36h204.8c8.704,0,15.36-6.656,15.36-15.36
			S323.584,0,314.88,0z"
			/>
		</IconBase>
	)
}

export function Book(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Book icon" {...props} viewBox="0 0 929.2 858">
			<path
				fill="currentColor"
				d="M882,172c0.7,12.7-0.3,23.3-3,32L712,754c-3.3,9.3-10,17-20,23s-20.3,9-31,9H146c-44.7,0-71.7-13-81-39c-3.3-10-3-18,1-24 c4-5.3,11-8,21-8h485c34.7-0.7,58.7-7.3,72-20s26.7-41,40-85l153-506c8.7-26.7,5.3-50.7-10-72S790.3,0,763,0H338c-4.7,0-14,1.7-28,5 V4c-10.7-2.7-19.3-3.7-26-3s-13.3,2.7-20,6s-11.7,7.7-15,13s-7,11.3-11,18s-7,13.3-9,20s-4.7,13.3-8,20s-6.3,12.3-9,17 c-2.7,2.7-6,6.3-10,11s-7.3,9-10,13s-4.3,7.3-5,10c-0.7,3.3-0.3,8.3,1,15s2,11.3,2,14c-2,14-7.3,32-16,54s-16.3,37.7-23,47 c-2,2-6.3,6.3-13,13c-6.7,6.7-10.7,12.3-12,17c-1.3,2.7-1.3,8,0,16s1.7,13.7,1,17c-2,13.3-6.7,30.3-14,51s-15,37.7-23,51 c-2,2.7-5.3,7-10,13s-7.7,11-9,15c-1.3,3.3-1.3,8.7,0,16s1.3,13,0,17c-3.3,14-9,31-17,51s-16.3,37-25,51c-2.7,4-5.7,8.3-9,13 s-6.3,9-9,13s-4.3,8-5,12c0,2.7,0.7,6.3,2,11s2,8.7,2,12c-1.3,4.7-2.3,11.3-3,20s-1,13.7-1,15c-8.7,22.7-8.3,46.3,1,71 c10,29.3,28.3,54,55,74s54.3,30,83,30h515c24.7-0.7,47.7-9,69-25s35.3-36,42-60l154-505c8-27.3,4.7-51.3-10-72 C907,185.3,896,177.3,882,172z M333,161c1.3-4,4.3-8,9-12s9.3-6,14-6h339c6,0.7,10,2.7,12,6s2.3,7.3,1,12l-12,36c-1.3,4-4.3,8-9,12 s-9.7,6-15,6H333c-5.3-0.7-9-2.7-11-6s-2.3-7.3-1-12L333,161z M286,304c2-5.3,5.3-9.7,10-13s9.3-5,14-5h339c4.7-0.7,8.3,1,11,5 s3,8.3,1,13l-11,36c-2,4-5.3,8-10,12s-9.3,6-14,6H287c-4.7-0.7-8.3-2.7-11-6s-3-7.3-1-12L286,304z"
			/>
		</IconBase>
	)
}

export function Gamepad(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Gamepad icon" {...props} viewBox="0 0 1071 571">
			<path
				fill="currentColor"
				d="M84,487c55.3,56,122.7,84,202,84c71.3,0,134-23.7,188-71h123c54.7,47.3,117.7,71,189,71c80-0.7,147.3-28.7,202-84 c54.7-55.3,82.3-122.7,83-202s-27-146.7-83-202S864.7,0,786,0H286C207.3-0.7,140,27,84,83S0,206.3,0,285C0.7,363.7,28.7,431,84,487z  M107,250c0-5.3,1.7-9.7,5-13s7.7-5,13-5h107V125c0-5.3,1.7-9.7,5-13s7.7-5,13-5h71c5.3,0,9.7,1.7,13,5s5,7.7,5,13v107h107 c5.3,0,9.7,1.7,13,5s5,7.7,5,13v71c0,5.3-1.7,9.7-5,13c-3.3,3.3-7.7,5-13,5H339v107c0,5.3-1.7,9.7-5,13c-3.3,3.3-7.7,5-13,5h-71 c-5.3,0-9.7-1.7-13-5c-3.3-3.3-5-7.7-5-13V339H125c-5.3,0-9.7-1.7-13-5c-3.3-3.3-5-7.7-5-13V250z M664,306 c12.7-13.3,29.3-20.3,50-21s37.7,6.3,51,21s20.3,31.7,21,51c0.7,19.3-6.3,36-21,50s-31.7,21-51,21s-36-7-50-21s-21-30.7-21-50 C644.3,336.3,651.3,319.3,664,306z M807,163c12.7-11.3,29.3-18,50-20s37.7,4.7,51,20s20.3,32.3,21,51s-6.3,35.3-21,50 c-14.7,14.7-31.7,21.7-51,21s-36-7.7-50-21c-14-13.3-21-30-21-50C787.3,191.3,794.3,174.3,807,163z"
			/>
		</IconBase>
	)
}

export function Github(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Github icon" {...props} viewBox="0 0 32.58 31.77">
			<path
				fill="currentColor"
				d="M16.29,0C7.29,0,0,7.29,0,16.29c0,7.2,4.67,13.3,11.14,15.46c0.81,0.15,1.11-0.35,1.11-0.79
	c0-0.39-0.01-1.41-0.02-2.77c-4.53,0.98-5.49-2.18-5.49-2.18C6,24.13,4.93,23.62,4.93,23.62c-1.48-1.01,0.11-0.99,0.11-0.99
	c1.63,0.12,2.5,1.68,2.5,1.68c1.45,2.49,3.81,1.77,4.74,1.35c0.15-1.05,0.57-1.77,1.03-2.18C9.7,23.08,5.9,21.68,5.9,15.44
	c0-1.78,0.63-3.23,1.68-4.37C7.41,10.65,6.85,9,7.73,6.76c0,0,1.37-0.44,4.48,1.67c1.3-0.36,2.69-0.54,4.08-0.55
	c1.38,0.01,2.78,0.19,4.08,0.55c3.11-2.11,4.48-1.67,4.48-1.67c0.89,2.24,0.33,3.9,0.16,4.31c1.04,1.14,1.67,2.59,1.67,4.37
	c0,6.26-3.81,7.63-7.44,8.04c0.58,0.5,1.11,1.5,1.11,3.02c0,2.18-0.02,3.93-0.02,4.47c0,0.44,0.29,0.94,1.12,0.78
	c6.47-2.16,11.13-8.26,11.13-15.45C32.58,7.29,25.29,0,16.29,0z"
			/>
		</IconBase>
	)
}

export function Mail(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Mail icon" {...props} viewBox="0 0 1113 820">
			<path
				fill="currentColor"
				d="M 0 732 L 0 88 C 0 82.7 0.7 76.7 2 70 L 462 530 C 488 556 519.7 569 557 569 C 593.7 569 625 556 651 530 L 1111 70 C 1112.3 76 1113 82 1113 88 L 1113 732 C 1113 756.7 1104.3 777.3 1087 794 C 1069.7 810.7 1049 819.3 1025 820 L 88 820 C 64 820 43.3 811.3 26 794 C 8.7 776.7 0 756 0 732 Z  M 63 4 C 71.7 1.3 80 0 88 0 L 1025 0 C 1033 0 1041.3 1.3 1050 4 L 587 467 C 578.3 475 568 479 556 479 C 544 479 534 475 526 467 L 63 4 L 63 4 Z"
			/>
		</IconBase>
	)
}

export function Medal(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Medal icon" {...props} viewBox="0 0 52 104">
			<path
				fill="currentColor"
				d="M25.7,50C11.7,50,0.5,61.3,0.5,75.2c0,13.9,11.3,25.2,25.2,25.2s25.2-11.3,25.2-25.2C50.8,61.3,39.6,50,25.7,50z M38.2,84.3l-1.3,6.3l-0.5-0.1l-1.8-0.2h0l-0.7-0.1l-0.3,0h0l-3.1-0.3l-1.8,1.6l0,0l-1.2,1.1L27,93l-0.7,0.6l-0.6,0.6L25,93.6 24.3,93l-0.5-0.4l-1.5-1.4l-1.4-1.3L19,90.1h0l-0.5,0.1l-1.8,0.2L15,90.5l-0.5,0.1l-1.3-6.3l-5.6-3.2l2.6-5.9l-2.6-5.9l5.6-3.2 1.3-6.3l6.4,0.7l4.8-4.3l4.8,4.3l6.4-0.7l1.3,6.3l5.6,3.2l-2.6,5.9l2.6,5.9L38.2,84.3z"
			/>
			<path
				fill="currentColor"
				d="M30.3,45.7H21c-9.4,0-17-7.6-17-17V0.5h43.3v28.2C47.3,38.1,39.7,45.7,30.3,45.7z"
			/>
		</IconBase>
	)
}

export function Paper(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Paper icon" {...props} viewBox="0 0 700 900">
			<path
				fill="currentColor"
				d="M600,0c28,0,51.7,9.7,71,29s29,43,29,71v700c0,26.7-9.7,50-29,70s-43,30-71,30H100c-26.7,0-50-10-70-30S0,826.7,0,800V100 c0-28,10-51.7,30-71S73.3,0,100,0H600z M140,208v88h420v-88H140z M560,688v-88H140v88H560z M560,492v-90H142v90H560z"
			/>
		</IconBase>
	)
}

export function Poster(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Poster icon" {...props} viewBox="0 0 755.4 628.5">
			<path
				fill="currentColor"
				d="M278,565.5l-93,48c-16,10-37,15-57,15c-48,0-91-26-113-66c-17-30-19-65-9-97c9-31,31-59,61-74l94-50c2,0,2,0,2,0 c4,0,78-7,124,76C332,497.5,283,558.5,278,565.5z M193,462.5c-30-50-89-69-132-43c-44,26-55,87-26,135c30,50,89,69,132,43 C211,571.5,222,510.5,193,462.5z M750,276.5c-9,32-31,60-61,75l-380,200c15-30,28-82-5-141c-34-59-80-78-111-84l44-24l109-296 c2-2,4-4,6-5c2-2,5-2,7,0l219,124c16-8,33-12,50-12c48,0,91,26,113,67C757,210.5,759,244.5,750,276.5z M559,134.5l-200-111l-98,266 L559,134.5z"
			/>
		</IconBase>
	)
}

export function Quote(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Quote icon" {...props} viewBox="0 0 929 786">
			<path
				fill="currentColor"
				d="M31,755c21.4,20.7,46.7,31,76,31h214c29.4,0,54.7-10.3,76-31s32-46,32-76V465c-0.7-30-11.3-55.3-32-76s-46-31-76-31H196 c-14,0-26.3-5.3-37-16s-16-23.3-16-38v-18c0-39.3,14-73,42-101s61.7-42,101-42h35c10.7,0.7,19.3-2.7,26-10s10-15.7,10-25V36 c0.7-9.3-2.7-17.7-10-25S331,0,321,0h-35c-38.7,0-75.7,7.7-111,23s-65.7,35.7-91,61s-45.7,55.7-61,91S0,247.3,0,286v393 C-0.6,709,9.6,734.3,31,755z M531,755c21.3,20.7,46.7,31,76,31h214c29.4,0,54.7-10.3,76-31s32-46,32-76V465c-0.7-30-11.3-55.3-32-76 s-46-31-76-31H696c-15.3,0-28-5.3-38-16s-15-23.3-15-38v-18c0-39.3,14-73,42-101s61.7-42,101-42h35c10.7,0.7,19.3-2.7,26-10 s10-15.7,10-25V36c0.7-9.3-2.7-17.7-10-25S831,0,821,0h-35c-38.7,0-75.7,7.7-111,23s-65.7,35.7-91,61s-45.7,55.7-61,91 s-23,72.3-23,111v393C499.4,709,509.7,734.3,531,755z"
			/>
		</IconBase>
	)
}

export function Stats(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Statistics icon" {...props} viewBox="0 0 478 478">
			<path
				fill="currentColor"
				d="M119.5,187.75H17.1c-9.4,0-17,7.6-17.1,17.1v256c0,9.5,7.7,17.1,17.1,17.1h102.4c9.5,0,17.1-7.7,17.1-17.1v-256
			C136.6,195.35,128.9,187.75,119.5,187.75z"
			/>
			<path
				fill="currentColor"
				d="M290.2,0.05H187.8c-9.4,0-17.1,7.6-17.1,17v443.8c0,9.5,7.7,17.1,17.1,17.1h102.4c9.5,0,17.1-7.7,17.1-17.1V17.15
			C307.3,7.65,299.6,0.05,290.2,0.05z"
			/>
			<path
				fill="currentColor"
				d="M460.9,136.55H358.5c-9.5,0-17.1,7.6-17.1,17.1v307.2c0,9.5,7.7,17.1,17.1,17.1h102.4c9.5,0,17.1-7.7,17.1-17.1v-307.2
			C478,144.15,470.3,136.55,460.9,136.55z"
			/>
		</IconBase>
	)
}

export function Trophy(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Trophy icon" {...props} viewBox="0 0 826 937">
			<path
				fill="currentColor"
				d="M0,273c0.7-23.3,6.7-44.7,18-64c13.3-22,32.3-38,57-48s52-12.7,82-8c-0.7-21.3-1-38-1-50c0-29.3,23-54,69-74 S333.7-0.6,413,0c79.3,0,142,9.7,188,29s69,44,69,74c0,1.3-0.3,18-1,50c29.3-4.7,56.7-2,82,8s44.7,26,58,48c11.3,19.3,17,40.7,17,64 s-5.3,46-16,68c-9.3,18-24.7,35-46,51s-52.7,36.3-94,61c-24,14.7-41.7,25.7-53,33s-26.7,17.7-46,31c-19.3,13.3-35.7,26-49,38 c-13.3,12-23.7,24-31,36c-8,16.7-12,35.7-12,57c0,32.7,10.7,63.3,32,92c21.3,28.7,46,47.7,74,57c27.3,8,45.7,17,55,27s14,24.3,14,43 c0,18.7-22.3,35-67,49s-102.7,21-174,21s-129.3-7-174-21s-67-30.3-67-49c0-18.7,4.7-33,14-43s28-19,56-27c28-8.7,52.7-27.7,74-57 c21.3-29.3,32-60,32-92c0-21.3-4.3-40.3-13-57c-6-9.3-14.7-19.7-26-31c-11.3-11.3-21.7-20.3-31-27c-9.3-6.7-23-16.3-41-29 s-31-21.3-39-26s-22-13-42-25c-27.3-16-48.3-29-63-39s-29.7-21.3-45-34s-26-25.7-32-39C4.7,318.4-0.7,295.7,0,273z M90,254 c-8,12.7-7.3,29,2,49c6.7,12,39.7,36,99,72c-12.7-38.7-21.7-83-27-133c-1.3-0.7-3.7-1.3-7-2s-5.3-1.3-6-2c-12-2-18.7-3-20-3 C111.7,235,98,241.4,90,254z M251,107c0,13.3,16,24.3,48,33s70,13.3,114,14c44.7,0,82.7-4.7,114-14s47-20.3,47-33s-15.7-23.7-47-33 s-69.3-14-114-14s-82.7,4.7-114,14S251.7,94.4,251,107z M635,375c60-36,93-60,99-72c9.3-20,10-36.3,2-49c-10.7-16.7-31-22-61-16 c-2,0.7-6,2-12,4C656.3,294.7,647,339,635,375z"
			/>
		</IconBase>
	)
}

export function Video(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Video icon" {...props} viewBox="0 0 639.4 447.7">
			<path
				fill="currentColor"
				d="M626.2,70c-7.4-27.4-29-49-56.4-56.4C519.8,0,319.7,0,319.7,0S119.6,0,69.5,13.2c-26.9,7.4-49,29.5-56.4,56.9 C0,120.1,0,223.8,0,223.8s0,104.3,13.2,153.8c7.4,27.4,29,49,56.4,56.4c50.6,13.7,250.2,13.7,250.2,13.7s200.1,0,250.2-13.2 c27.4-7.4,49-29,56.4-56.4c13.2-50,13.2-153.8,13.2-153.8S639.9,120.1,626.2,70z M256,319.7V128l166.4,95.9L256,319.7z"
			/>
		</IconBase>
	)
}

export function Download(props: IconProps): React.ReactElement {
	return (
		<IconBase title="Download icon" {...props} viewBox="0 0 512 426">
			<path
				fill="currentColor"
				d=" M 30.175 341 L 481.825 341 C 498.479 341 512 354.521 512 371.175 L 512 395.825 C 512 412.479 498.479 426 481.825 426 L 30.175 426 C 13.521 426 0 412.479 0 395.825 L 0 371.175 C 0 354.521 13.521 341 30.175 341 Z "
			/>
			<path
				fill="currentColor"
				d=" M 309.5 150 L 309.5 29.425 C 309.5 13.185 296.315 0 280.075 0 L 231.925 0 C 215.685 0 202.5 13.185 202.5 29.425 L 202.5 150 L 143 150 L 143 150 C 134.721 150 128 156.721 128 165 C 128 168.635 129.296 171.971 131.455 174.564 L 132.868 176.044 C 133.044 176.205 133.224 176.361 133.407 176.514 C 133.393 176.531 133.379 176.548 133.365 176.565 L 246.164 294.698 C 248.838 296.762 252.266 298 256 298 C 259.734 298 263.162 296.762 265.836 294.698 L 380.58 174.528 C 382.717 171.938 384 168.617 384 165 C 384 156.842 377.473 150.196 369.36 150.006 C 369.36 150.004 369.36 150.002 369.36 150 L 309.5 150 Z "
			/>
		</IconBase>
	)
}

export function CopyToClipboard(props: IconProps) {
	return (
		<IconBase title="Copy to clipboard icon" {...props} viewBox="0 0 384 512">
			<path
				fill="currentColor"
				d="M192 0c-41.8 0-77.4 26.7-90.5 64H48C21.5 64 0 85.5 0 112V464c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H282.5C269.4 26.7 233.8 0 192 0zm0 128c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32zm-80 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"
			/>
		</IconBase>
	)
}

type IconBaseProps = {
	title: string
} & React.JSX.IntrinsicElements["svg"]
function IconBase({ title, children, ...svgProps }: IconBaseProps) {
	return (
		<svg {...svgProps}>
			<title>{title}</title>
			{children}
		</svg>
	)
}

export type IconComponent = (props: IconProps) => React.ReactElement
